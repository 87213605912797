import { FieldValues, UseFormRegister, UseFormWatch } from "react-hook-form";
import { ISurveyQuestionItemGroupsQuestionItem } from "../../libs/interfaces/services";
import { DefaultError } from "../DefaultError";
import { TextAreaDefault } from "../TextAreaDefault";
import styles from "./styles.module.css";
import { AIAssistantButton } from "../ChatWidget";
import { QuestionType } from "../../models/QuestionTypeEnum";
import useAssistantStore, { AssistantStore } from "../../store/assistantStore";

interface ITextProps {
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<any>;
  options: ISurveyQuestionItemGroupsQuestionItem[];
  id: string | number;
  errors?: any;
  text: string;
  isAssistantActive: boolean;
}

export const IntItem = ({ register, watch, id, errors, text = "", isAssistantActive }: ITextProps) => {
  const setInput = useAssistantStore((state: AssistantStore) => state.setInput);
  if (isAssistantActive) {
    setInput({
      type: QuestionType.Int,
      data: parseInt(watch(`${id}`)),
      value: parseInt(watch(`${id}`))
    });
  }

  return (
    <div className={styles.container}>
      <div
        dangerouslySetInnerHTML={{
          __html: text ? text : "",
        }}
      />
      <div>
        <div className={'buttonContainer'} style={{ marginTop: "15px" }}>
          <AIAssistantButton
            subQuestion={{ id: id as number, text: text }}
          />
        </div>
        <div className={styles.containerInputNumber}>
          <input
            className={styles.inputNumber}
            style={errors && { borderColor: "red" }}
            type="number"
            {...register(`${id}`)}
          />
          {errors && <DefaultError />}
        </div>
      </div>
    </div>
  );
};
