import "./index.css";
import { App } from "./App";
import ReactDOM from "react-dom/client";

// const container = document.getElementById("root");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);
