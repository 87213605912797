/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from "./Survey.module.css";
import { useCallback, useEffect, useState } from "react";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import surveysService from "../../services/surveys";
import { useParams } from "react-router-dom";
import { ISurveyQuestion } from "../../libs/interfaces/services";
import { useContextSurveyQuiz } from "../../hooks/useSurveyQuiz";

import gifOpen from "../../assets/gif_open_2.gif";
import useAssistantStore, { AssistantStore } from "../../store/assistantStore";

interface IFinalMessage {
  title: string;
  description: string;
  subtitle: string;
}

function SurveyTopicFinish() {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [surveyTopic, setSurveyTopic] = useState<ISurveyQuestion>();

  const [finalMessage, setFinalMessage] = useState<IFinalMessage>({
    title: "",
    description: "",
    subtitle: "",
  });

  const { surveyTopicID, surveyID } = params;

  useEffect(() => {
    const handleGetFinalMessage = async () => {
      const response = await surveysService.SurveyGetFinalMessage(
        surveyID || ""
      );
      setFinalMessage(response?.object);
    };
    handleGetFinalMessage();
  }, []);

  const setHasAssistant = useAssistantStore((state: AssistantStore) => state.setHasAssistant);

  useEffect(() => {
    async function loadSurveyQuestion() {
      if (params.surveyID) {
        const response = await surveysService.listSurveyQuestions(
          params.surveyID,
          params.hashID ? params.hashID : ""
        );

        setHasAssistant(response.object.hasAssistant);

        const resp = await surveysService.listSurveyAnswer(
          params.surveyID,
          params.hashID ? params.hashID : ""
        );

        setSurveyTopic(
          response.object.topics.find(
            (topic) => topic.idTopic === Number(surveyTopicID)
          )
        );
        setLoading(false);
      }
    }

    loadSurveyQuestion();
  }, []);

  function handleChangeStartQuiz() {
    window.location.href = `/${params.hashID}`;
  }

  return (
    <>
      <div className={styles.survey}>
        <div className={styles.surveyContent}>
          <div className={styles.surveyIconContainer}>
            <img src={gifOpen} alt="gif open" className={styles.gifOpen} />
          </div>

          <div className={styles.surveyContainerText}>
            <h1>{finalMessage?.title} </h1>
            <h2>{finalMessage?.subtitle}</h2>
            <h3>{finalMessage?.description}</h3>
          </div>

          <div className={styles.surveyContentFooter}>
            <Button
              handleAction={() => handleChangeStartQuiz()}
              text="Voltar ao inicio"
              type="lightBlue"
              width="100%"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default SurveyTopicFinish;
