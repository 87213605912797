import { LuSendHorizonal, LuSparkles, LuX } from 'react-icons/lu';
import styles from "./style.module.css";
import useAssistantStore, { AssistantStore } from '../../store/assistantStore';
import { useEffect, useRef, useState } from 'react';
import { askAssistant } from '../../services/assistant';
import Markdown from 'react-markdown';

const ChatWidget = () => {
    const closeChat = () => {
        deactivateAssistant();
    };

    const isActive = useAssistantStore((state: AssistantStore) => state.isActive);
    const { idSubQuestion, subQuestionText } = useAssistantStore((state: AssistantStore) => state.config);
    const input = useAssistantStore((state: AssistantStore) => state.input);
    const hashId = useAssistantStore((state: AssistantStore) => state.hashId);
    const deactivateAssistant = useAssistantStore((state: AssistantStore) => state.deactivateAssistant);
    const addChatMessage = useAssistantStore((state: AssistantStore) => state.addChatMessage);
    const chatMessages = useAssistantStore((state: AssistantStore) => state.chatMessages);
    const [isLoading, setIsLoading] = useState(false);
    const setIdChat = useAssistantStore((state: AssistantStore) => state.setIdChat);
    const idChat = useAssistantStore((state: AssistantStore) => state.idChat);
    const undoLastMessage = useAssistantStore((state: AssistantStore) => state.undoLastMessage);
    const [error, setError] = useState<boolean>(false);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setError(false);
    }, [input]);

    useEffect(() => {
        if (chatMessages.length === 0) {
            inputRef?.current?.focus();
            setTimeout(() => {
                addChatMessage({ role: "assistant", content: "Oi eu sou a Ana, como eu posso te ajudar?" });
            }, 1000);
        }
    }, [chatMessages]);

    useEffect(() => {
        if (!isLoading && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isLoading]);

    const sendMessage = async () => {
        if (message.trim() === '') return;

        const lastMessage = message;
        setIsLoading(true);
        addChatMessage({ role: "user", content: message });
        setMessage("");
        setError(false);
        try {
            const response = await askAssistant({ idChat: idChat!, idSubQuestion: idSubQuestion!, hashId: hashId!, input: input!, question: message });
            setIdChat(response.idChat);
            addChatMessage({ role: "assistant", content: response.text });
        } catch (error) {
            undoLastMessage();
            setMessage(lastMessage);
            setError(true);
        }
        setIsLoading(false);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && !isLoading) {
            sendMessage();
        }
    };

    const [message, setMessage] = useState("");

    const chatBodyRef = useRef<HTMLDivElement>(null);

    const hasAssistant = useAssistantStore((state: AssistantStore) => state.hasAssistant);

    useEffect(() => {
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }
    }, [chatMessages]);

    return (
        hasAssistant ? (
            <div className={`${styles.chatWidgetContainer} ${isActive ? styles.active : ""}`}>
                {isActive && (
                    <div className={styles.chatWidget}>
                        <div className={styles.chatHeader}>
                            <div className={styles.chatHeaderTitle}>
                                <LuSparkles style={{ color: "#a2c4ff", marginRight: "10px" }} />
                                <h3>
                                    <span>Ana IA</span>
                                </h3>
                            </div>
                            <button className={styles.chatCloseButton} onClick={closeChat}>
                                <LuX />
                            </button>
                        </div>
                        <div className={styles.chatBody} ref={chatBodyRef}>
                            {subQuestionText && <div className={styles.chatSubQuestionText} dangerouslySetInnerHTML={{ __html: subQuestionText }}></div>}
                            {chatMessages.length > 0 && chatMessages.map((message, index) => (
                                <div key={index} className={`${styles.chatMessage} ${message.role === "assistant" ? styles.assistant : styles.user}`}>
                                    <p className={`${styles.chatMessageRole} ${message.role === "assistant" ? styles.assistantRole : styles.userRole}`}>{message.role === "assistant" ? "Ana IA:" : "Usuário:"}</p>
                                    <div className={styles.chatMessageContent}>
                                        {message.role === "assistant" ? <Markdown>{message.content}</Markdown> : message.content}
                                    </div>
                                </div>
                            ))}
                            {/* {chatMessages.length === 0 && <p className={styles.chatNoMessages}>Faça sua pergunta para o assistente de RH</p>} */}

                            {error && <div className={styles.errorMessage}>
                                <p>Não foi possível obter resposta do assistente.</p>
                                <p>Tente novamente.</p>
                            </div>}

                            {isLoading && <div className={styles.loadingBarContainer}>
                                <div className={styles.loadingBar}></div>
                                {/* <span className={styles.loadingBarText}>Carregando...</span> */}
                            </div>}
                        </div>
                        <div className={styles["chat-footer"]}>
                            <input
                                ref={inputRef}
                                type="text"
                                placeholder="Digite sua mensagem..."
                                className={styles.chatInput}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                disabled={isLoading}
                                readOnly={isLoading}
                                onKeyDown={handleKeyDown}
                            />
                            <button className={styles.chatSendButton} onClick={sendMessage} disabled={isLoading}>
                                <LuSendHorizonal size={16} />
                            </button>
                        </div>
                    </div>
                )}
            </div>
        ) : (
            <></>
        )
    );
};

export default ChatWidget;

export const AIAssistantButton = ({ subQuestion }: { subQuestion: { id: number, text: string } }) => {
    const activateAssistant = useAssistantStore((state: AssistantStore) => state.activateAssistant);
    const hasAssistant = useAssistantStore((state: AssistantStore) => state.hasAssistant);
    return (
        hasAssistant ? (<button
            className={`${styles.aiAssistantButton} mainBg`}
            onClick={(e) => {
                activateAssistant(subQuestion.id, subQuestion.text);
                e.preventDefault();
            }}
        >
            {/* <SiCodemagic className={styles.aiAssistantButtonIcon} size={13} /> */}
            <span className={styles.aiAssistantButtonText}>Assistente IA</span>
        </button>) : (<></>)
    );
};