import { FieldValues, UseFormRegister, UseFormWatch } from "react-hook-form";
import { ISurveyQuestionItemGroupsQuestionItem } from "../../libs/interfaces/services";
import { DefaultError } from "../DefaultError";
import { InputMasked } from "../InputMasked";
import styles from "./style.module.css";
import { AIAssistantButton } from "../ChatWidget";
import { QuestionType } from "../../models/QuestionTypeEnum";
import useAssistantStore, { AssistantStore } from "../../store/assistantStore";

interface ITextProps {
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  id: string | number;
  errors?: any;
  text: string;
  isAssistantActive: boolean;
}

export const DateItem = ({ register, watch, id, errors, text = "", isAssistantActive }: ITextProps) => {

  const content = watch(`${id}`);

  const setInput = useAssistantStore((state: AssistantStore) => state.setInput);
  if (isAssistantActive) {
    setInput({
      type: QuestionType.Date,
      data: content,
      value: content
    });
  }

  return (
    <div className={styles.container}>
      <div>

        <div
          dangerouslySetInnerHTML={{
            __html: text ? text : "",
          }}
        />
        <div className={'buttonContainer'}>
          <AIAssistantButton
            subQuestion={{ id: Number(id), text: text }}
          />
        </div>
      </div>
      <InputMasked errors={!!errors} register={register(`${id}`)} />
      {errors && <DefaultError />}
    </div>
  );
};
