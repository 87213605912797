import React, { useState } from "react";
import Modal from "react-modal";
import { IoMdClose } from "react-icons/io";

import BackIcon from "../../assets/back-icon.png";

import styles from "./styles.module.css";
import Logo from "../../assets/logo-rh.png";
import GifClose from "../../assets/gif_close.gif";
import Button from "../Button";
import { useContextSurveyQuiz } from "../../hooks/useSurveyQuiz";
import { useParams } from "react-router-dom";

interface IHeader {
  handleNavigate: () => void;
}

export const Header = ({ handleNavigate }: IHeader) => {
  const [modalOpen, setModalOpen] = useState(false);

  const {
    showSurveyQuiz,
    surveyQuizIndex,
    setShowSurveyQuiz,
    numTotalQuestions,
    setSurveyQuizIndex,
    numberTopics,
  } = useContextSurveyQuiz();

  const { hashID, surveyID, surveyTopicID } = useParams();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      minHeight: "450px",
      maxWidth: "650px",
      borderRadius: "10px",
      backgroundColor: "#f2f2f7",
    },
  };

  const handleSave = () => {
    window.location.href = `/${hashID}`;

    // if (numberTopics.length <= 1) {
    //   window.location.href = `/${hashID}`;
    // } else {
    //   window.location.href = `/${hashID}/survey/${surveyID}`;
    // }
  };

  const handleBack = () => {
    if (surveyQuizIndex === 0) {
      numberTopics.forEach((topics, index) => {
        if (topics.idTopic === Number(surveyTopicID)) {
          if (index > 0) {
            window.location.href = `/${hashID}/survey-topic/${surveyID}/${
              numberTopics[index - 1].idTopic
            }`;
          } else {
            handleNavigate();
          }
        }
      });
    } else {
      setSurveyQuizIndex((old) => old - 1);
    }
  };

  return (
    <>
      <div className={styles.containerHeader}>
        <div
          className={styles.backArrow}
          onClick={() => {
            handleBack();
          }}
        >
          <img src={BackIcon} className={styles.backIcon} alt="back icon" />
        </div>
        <div>
          <img src={Logo} alt="logo-img" className={styles.logo} />
        </div>
        <div
          className={styles.closeButton}
          onClick={() => {
            if (showSurveyQuiz) {
              setModalOpen(true);
            } else {
              handleNavigate();
            }
          }}
        >
          <IoMdClose color="black" size={23} />
        </div>
      </div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName={styles.overlay}
      >
        <div className={styles.containerModal}>
          <img src={GifClose} alt="gif-close" className={styles.gifClose} />
          <h1>Sair do questionário?</h1>
          <h3>Você está deixando o questionário. O que gostaria de fazer? </h3>
          <div className={styles.wrapperButton}>
            <Button
              handleAction={() => handleSave()}
              text="Salvar e continuar depois"
              type="blueWhite"
              width="170px"
            />
            <Button
              handleAction={() => setModalOpen(false)}
              text="Voltar"
              type="darkTransparent"
              width="170px"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
