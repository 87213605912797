import styles from "./Button.module.css";
import GifLoading from "../../assets/gif_loading_orbs.gif";

interface IButtonProps {
  handleAction?(): void;
  text: string;
  type: string;
  width: string;
  typeButton?: "submit" | "reset" | "button";
  disabled?: boolean;
  loading?: boolean;
}

const Button: React.FC<IButtonProps> = ({
  handleAction,
  text,
  type,
  width,
  typeButton = "submit",
  disabled = false,
  loading = false,
}) => {
  return (
    <button
      type={typeButton}
      onClick={handleAction}
      className={styles[type]}
      style={{ width: width }}
      disabled={disabled}
    >
      {loading ? (
        <img className={styles.loadingGif} src={GifLoading} alt="loading gif" />
      ) : (
        <p>{text}</p>
      )}
    </button>
  );
};

export default Button;
