/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from "./Survey.module.css";
import { useEffect, useState } from "react";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import surveysService from "../../services/surveys";
import { useParams } from "react-router-dom";
import { ISurveyQuestion } from "../../libs/interfaces/services";
import { useContextSurveyQuiz } from "../../hooks/useSurveyQuiz";

import gifOpen from "../../assets/gif_open_2.gif";

function SurveyTopicContinue() {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [surveyTopic, setSurveyTopic] = useState<ISurveyQuestion>();

  const { surveyTopicID, surveyID, hashID } = params;

  const { setShowSurveyQuiz, setNumTotalQuestions } = useContextSurveyQuiz();

  useEffect(() => {
    async function loadSurveyQuestion() {
      if (params.surveyID) {
        const response = await surveysService.listSurveyQuestions(
          params.surveyID,
          params.hashID ? params.hashID : ""
        );

        const resp = await surveysService.listSurveyAnswer(
          params.surveyID,
          params.hashID ? params.hashID : ""
        );

        setSurveyTopic(
          response.object.topics.find(
            (topic) => topic.idTopic === Number(surveyTopicID)
          )
        );
        setLoading(false);
      }
    }

    loadSurveyQuestion();
  }, []);

  function handleChangeStartQuiz() {
    setShowSurveyQuiz(true);
    setNumTotalQuestions(
      surveyTopic?.questions ? surveyTopic?.questions.length : 0
    );
  }

  return (
    <>
      <div className={styles.survey}>
        <div className={styles.surveyContent}>
          <div className={styles.surveyIconContainer}>
            <img src={gifOpen} alt="gif open" className={styles.gifOpen} />
          </div>

          <h1>Obrigado!</h1>
          <h2>Sua participação é fundamental para nós!</h2>
          <h3>
            Agradecemos sua participação na Pesquisa de Satisfação #3, sua
            participação foi fundamental para a concretização deste projeto,
            contamos com você nas próximas etapas.
          </h3>
          <div className={styles.surveyContentFooter}>
            <Button
              handleAction={() => handleChangeStartQuiz()}
              text="Iniciar"
              type="lightBlue"
              width="100%"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default SurveyTopicContinue;
