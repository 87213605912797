import styles from "./Surveys.module.css";
import Footer from "../../components/Footer";
import DarkLogo from "../../assets/dark-logo.png";

export function SurveyDefault() {
  return (
    <>
      <div className={styles.surveys}>
        <>
          <img src={DarkLogo} alt="dark logo" />
        </>
      </div>
    </>
  );
}
