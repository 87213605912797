export const orderByList = (a, b) => {
  if (a?.surveyStatus?.order < b?.surveyStatus?.order) {
    return -1;
  }
  if (a?.surveyStatus?.order > b?.surveyStatus?.order) {
    return 1;
  }
  return 0;
};

export const orderByItems = (a, b) => {
  if (a?.order < b?.order) {
    return -1;
  }

  if (a?.order > b?.order) {
    return 1;
  }

  return 0;
};
