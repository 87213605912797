function SURVEYS(hash: string) {
  return `/api/Survey/${hash}`;
}

function SURVEY_QUESTIONS(surveyID: number | string, hash: string) {
  // const hash = "/%23123Pesquisa_Andamento";

  return `/api/Survey/GetQuestions/${encodeURI(hash)}/${surveyID}`;
}

function SURVEY_ANSWER(surveyID: number | string, hash: string) {
  // const hash = "/%23123Pesquisa_Andamento";

  return `/api/Answer/${hash}/${surveyID}`;
}

function SURVEY_ANSWER_POST(hash: string) {
  // const hash = "/%23123Pesquisa_Andamento";

  return `/api/Answer/${hash}`;
}

function SURVEY_ANSWER_POST_LIST(hash: string) {
  // const hash = "/%23123Pesquisa_Andamento";

  return `/api/Answer/postlist/${hash}`;
}

function SURVEY_FINAL_MESSAGE(surveyId: string) {
  return `/api/Survey/GetFinalMessage/${surveyId}`;
}

function ASSISTANT_GET_CHAT(idSubQuestion: number, hashId: string) {
  return `/api/Assistant/${idSubQuestion}/${hashId}`;
}

function ASSISTANT_ADD_MESSAGE() {
  return `/api/Assistant/ask`;
}

const URLS = {
  SURVEYS,
  SURVEY_QUESTIONS,
  SURVEY_ANSWER,
  SURVEY_ANSWER_POST,
  SURVEY_ANSWER_POST_LIST,
  SURVEY_FINAL_MESSAGE,
  ASSISTANT_GET_CHAT,
  ASSISTANT_ADD_MESSAGE,
};

export default URLS;
