import Checkbox from "@material-ui/core/Checkbox";

import { FieldValues, UseFormRegister, UseFormWatch } from "react-hook-form";
import { FormControlLabel } from "@material-ui/core";
import { ISurveyQuestionItemGroupsQuestionItem } from "../../libs/interfaces/services";
import styles from "./style.module.css";
import { DefaultError } from "../DefaultError";
import { QuestionType } from "../../models/QuestionTypeEnum";
import { AIAssistantButton } from "../ChatWidget";
import useAssistantStore, { AssistantStore } from "../../store/assistantStore";

interface ICheckboxItem {
  id: number;
  options: ISurveyQuestionItemGroupsQuestionItem[];
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<any>;
  text: string;
  errors?: any;
  isAssistantActive: boolean;
}

export const CheckboxItem = ({
  id,
  options,
  register,
  watch,
  text,
  errors,
  isAssistantActive
}: ICheckboxItem) => {

  const setInput = useAssistantStore((state: AssistantStore) => state.setInput);
  if (isAssistantActive) {
    setInput({
      type: QuestionType.MultiSelect,
      data: options,
      value: options.map((option, index) => parseInt(watch(`${id}[${index}]`))).filter(value => value)
    });
  }

  return (
    <div className={styles.container}>
      <div
        dangerouslySetInnerHTML={{
          __html: text ? text : "",
        }}
      />
      <div className={styles.wrapperCheck}>
        <div className={'buttonContainer'} style={{ marginBottom: "-10px" }}>
          <AIAssistantButton
            subQuestion={{ id: id, text: text }}
          />
        </div>
        {options.map((option, index) => {
          const active = watch(`${id}[${index}]`);
          return (
            <FormControlLabel
              style={{ width: "100%", marginLeft: "0", marginRight: "0" }}
              key={option.idQuestionIten}
              className={
                active ? styles.containerCheckActive : styles.containerCheck
              }
              control={
                <Checkbox
                  color={"secondary"}
                  value={option.idQuestionIten}
                  {...register(`${id}[${index}]`)}
                  checked={!!active}
                  data-indeterminate={false}
                />
              }
              label={option.text}
            />
          );
        })}
      </div>
      {errors && <DefaultError />}
    </div>
  );
};
