import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ISurveyQuestion } from "../../libs/interfaces/services";
import styles from "./styles.module.css";
import lineButton from "../../assets/line-button.png";
import { useContextSurveyQuiz } from "../../hooks/useSurveyQuiz";

export function SurveyTopic({
  title,
  subtitle,
  description,
  idTopic,
}: ISurveyQuestion) {
  const params = useParams();

  const { surveyQuizIndex, setShowSurveyQuiz, setSurveyQuizIndex } =
    useContextSurveyQuiz();

  function continueSurvey() {
    // navigate(`/survey-topic/${params.surveyID}/${id}`);
    window.location.href = `/${params.hashID}/survey-topic/${params.surveyID}/${idTopic}`;
  }

  const handleBack = () => {
    if (surveyQuizIndex === 0) {
      setShowSurveyQuiz(false);
      // navigate(`/survey-topic/${params.surveyID}/${params.surveyTopicID}`);
    } else {
      setSurveyQuizIndex(surveyQuizIndex - 1);
    }
  };

  const handleNextQuestion = () => {
    setSurveyQuizIndex(surveyQuizIndex + 1);
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapperTitle}>
        <p>{title}</p>
      </div>

      <div className={styles.wrapperButton}>
        <img src={lineButton} alt="button line" className={styles.buttonLine} />
        <button
          type="submit"
          className={styles.buttonSurvey}
          title="iniciar"
          onClick={() => continueSurvey()}
        >
          Iniciar
        </button>
      </div>
    </div>
  );
}
