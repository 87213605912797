import styles from "./Survey.module.css";
import { useEffect, useRef, useState } from "react";
import Footer from "../../components/Footer";
import DarkLogo from "../../assets/dark-logo.png";
import surveysService from "../../services/surveys";
import { useParams, useNavigate } from "react-router-dom";
import {
  ISurveysResponse,
  ISurveyQuestionsResponse,
  ISurveyItem,
  ISurveyQuestion,
} from "../../libs/interfaces/services";
import { SurveyTopic } from "../../components/SurveyTopic";
import { Header } from "../../components/Header";
import gifOpen from "../../assets/gif_open_2.gif";
import { Loading } from "../../components/Loading";
import { useContextSurveyQuiz } from "../../hooks/useSurveyQuiz";
import useAssistantStore, { AssistantStore } from "../../store/assistantStore";

function SurveyTopics() {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [survey, setSurvey] = useState<ISurveyItem>();
  const [surveys, setSurveys] = useState<ISurveysResponse>();
  const [surveyQuestion, setSurveyQuestion] =
    useState<ISurveyQuestionsResponse>();
  const [surveyTopics, setSurveyTopics] = useState<ISurveyQuestion[]>();


  const { setNumberTopics } = useContextSurveyQuiz();

  useEffect(() => {
    async function loadSurveys() {
      const response = await surveysService.listSurveys(
        params.hashID ? params.hashID : ""
      );
      setSurveys(response);
    }

    loadSurveys();
  }, []);

  useEffect(() => {
    async function loadSurvey() {
      const surveyList = surveys?.object?.surveyList;

      if (params.surveyID && surveyList) {
        setSurvey(
          surveyList.find((item) => {
            return Number(item.idSurvey) == Number(params.surveyID);
          })
        );
      }
    }

    loadSurvey();
  }, [surveys]);

  const setHasAssistant = useAssistantStore((state: AssistantStore) => state.setHasAssistant);

  useEffect(() => {
    async function loadSurveyQuestion() {
      if (params.surveyID) {
        const response = await surveysService.listSurveyQuestions(
          params.surveyID,
          params.hashID ? params.hashID : ""
        );

        setHasAssistant(response.object.hasAssistant);

        const topics = response.object.topics;
        setSurveyTopics(topics);
        setSurveyQuestion(response);
      }

      setLoading(false);
    }

    loadSurveyQuestion();
  }, [surveys]);

  return (
    <>
      <section className={styles.survey}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className={styles.box}>
              <Header
                handleNavigate={() => {
                  navigate(`/${params.hashID}`);
                }}
              />
              <div className={styles.surveyIconContainer}>
                <img src={gifOpen} alt="gif open" className={styles.gifOpen} />
              </div>
            </div>

            <div className={styles.surveyContent}>
              <h1>{survey?.title}</h1>
              <h2>{survey?.subtitle}</h2>
              <div className={styles.statusBox}>
                <div style={{ background: survey?.surveyStatus?.color }} />
                <p>{survey?.surveyStatus?.description}</p>
              </div>
              <h3>{survey?.description}</h3>
              <div className={styles.surveyContentFooter}>
                <div>
                  {surveyTopics?.map((topic) => (
                    <div key={topic.idTopic}>
                      <SurveyTopic {...topic} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </section>

      <Footer />
    </>
  );
}

export default SurveyTopics;
