import styles from "./styles.module.css";
import DarkLogo from "../../assets/dark-logo.png";

export const Loading = () => {
  return (
    <div className={styles.container}>
      <img src={DarkLogo} className={styles.imageLoading} />
      <p className={styles.loadingMessage}>Loading...</p>
    </div>
  );
};
