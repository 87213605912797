import axios from "axios";

export default axios.create({
  //devlop
  //baseURL: "https://surveyrh.azurewebsites.net/",
  //prod
  // baseURL: "//jameswebbapp.azurewebsites.net",
  // baseURL: "//10.211.55.4:5191",
  baseURL: "//jameswebbv2.azurewebsites.net/",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
  },
});
