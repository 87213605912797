import Footer from "../../components/Footer";
import Button from "../../components/Button";
import styles from "./SurveyCompleted.module.css";
import ProgressBar from "../../components/ProgressBar";
import CircleIcons from "../../assets/circle-icons.png";

function SurveyCompleted() {
  return (
    <>
      <section className={styles.surveyCompleted}>
        <div className={styles.box}>
          <img src={CircleIcons} alt="teste" />
          <ThanksBox />
          <div className={styles.surveyCompletedFooter}>
            <Button
              handleAction={goToSurveys}
              text="Voltar ao Início"
              type="lightTransparent"
              width="300px"
            />
            <ProgressBar
              description="Progresso"
              type="light"
              percentage={100}
            />
          </div>
        </div>
      </section>
    </>
  );
}

function ThanksBox() {
  return (
    <div className={styles.thanksBox}>
      <h1>Obrigado!</h1>
      <h2>Sua participação é fundamental para nós!</h2>
      <h3>
        Agradecemos sua participação na pesquisa de satisfação na{" "}
        <strong>Pesquisa de Satisfação #3</strong>, sua participação foi
        fundamental para a concretização deste projeto, contamos com você nas
        próximas etapas.
      </h3>
    </div>
  );
}

function goToSurveys() {}

export default SurveyCompleted;
