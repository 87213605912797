import api from "./api";
import URLS from "../constants/urls";
import {
  ISurveysResponse,
  ISurveyQuestionsResponse,
  ISurveyAnswerItem,
  IResponseSurveyAnswer,
  ISurveyQuestion,
  ISurveyQuestionItemGroupsQuestion,
  ISurveyQuestionItemGroupsQuestionItem,
  ISurveyQuestionItem,
  IFormData,
} from "../libs/interfaces/services";
import { AxiosResponse } from "axios";
import useAssistantStore, { AssistantStore } from "../store/assistantStore";

async function listSurveys(hash: string): Promise<ISurveysResponse> {
  const endpoint = URLS.SURVEYS(hash);

  const { data } = await api.get<ISurveysResponse>(endpoint);

  return data;
}

async function listSurveyQuestions(
  surveyID: number | string,
  hash: string
): Promise<ISurveyQuestionsResponse> {
  const endpoint = URLS.SURVEY_QUESTIONS(surveyID, hash);

  const { data } = await api.get<ISurveyQuestionsResponse>(endpoint);

  // TODO: Remove when endpoint changes idGroupQuestionIten to idSubQuestion
  data.object.topics.forEach((topic: ISurveyQuestion) => {
    topic.questions.forEach((group: ISurveyQuestionItem) => {
      group.groupsQuestionItens.forEach((item: ISurveyQuestionItemGroupsQuestion) => {
        if ((item as any).idGroupQuestionIten) {
          item.idSubQuestion = (item as any).idGroupQuestionIten;
          delete (item as any).idGroupQuestionIten;
        }
      });
    });
  });

  return data;
}

async function listSurveyAnswer(
  surveyID: number | string,
  hash: string
): Promise<IResponseSurveyAnswer> {
  const endpoint = URLS.SURVEY_ANSWER(surveyID, hash);

  const { data } = await api.get<IResponseSurveyAnswer>(endpoint);

  return data;
}

async function SurveyAnswerItem(
  answer: ISurveyAnswerItem,
  hash: string
): Promise<AxiosResponse> {
  const endpoint = URLS.SURVEY_ANSWER_POST(hash);

  const response = await api.post(endpoint, answer);

  return response;
}

async function SurveyAnswerList(
  answers: ISurveyAnswerItem[],
  hash: string
): Promise<AxiosResponse> {
  const endpoint = URLS.SURVEY_ANSWER_POST_LIST(hash);

  const resp = await api.post(endpoint, answers);

  return resp;
}

async function SurveyGetFinalMessage(surveyId: string) {
  const endpoint = URLS.SURVEY_FINAL_MESSAGE(surveyId);

  const { data } = await api.get(endpoint);

  return data;
}

export default {
  listSurveys,
  listSurveyQuestions,
  listSurveyAnswer,
  SurveyAnswerItem,
  SurveyAnswerList,
  SurveyGetFinalMessage,
};
