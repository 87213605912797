import { FieldValues, UseFormRegister, UseFormWatch } from "react-hook-form";
import { FormControlLabel } from "@material-ui/core";
import { ISurveyQuestionItemGroupsQuestionItem } from "../../libs/interfaces/services";
import styles from "./style.module.css";
import { DefaultError } from "../DefaultError";
import { AIAssistantButton } from "../ChatWidget";
import { QuestionType } from "../../models/QuestionTypeEnum";
import useAssistantStore, { AssistantStore } from "../../store/assistantStore";

interface ICheckboxItem {
  id: number;
  options: ISurveyQuestionItemGroupsQuestionItem[];
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<any>;
  text: string;
  errors?: any;
  isAssistantActive: boolean;
}

export const RadioItem = ({
  id,
  options,
  register,
  watch,
  text,
  errors,
  isAssistantActive
}: ICheckboxItem) => {

  const setInput = useAssistantStore((state: AssistantStore) => state.setInput);
  if (isAssistantActive) {
    setInput({
      type: QuestionType.Radio,
      data: options,
      value: parseInt(watch(`${id}`))
    });
  }

  return (
    <div className={styles.container}>
      <div
        dangerouslySetInnerHTML={{
          __html: text ? text : "",
        }}
      />
      <div>
        <div className={'buttonContainer'} style={{ marginBottom: "-10px" }}>

          <AIAssistantButton
            subQuestion={{ id: id, text: text }}
          />
        </div>
      </div>
      {options.map((option, index) => {
        const active = watch(`${id}`) == option.idQuestionIten;

        return (
          <div key={`${id}-${index}`}  >
            <FormControlLabel
              style={{ width: "100%", marginLeft: "0", marginRight: "0" }}
              key={option.idQuestionIten}
              className={
                active ? styles.containerCheckActive : styles.containerCheck
              }
              control={
                <label>
                  <input
                    style={{ display: "none" }}
                    {...register(`${id}`)}
                    type="radio"
                    value={option.idQuestionIten}
                  />
                </label>
              }
              label={option.text}
            />
          </div>
        );
      })}
      {errors && <DefaultError />}
    </div>
  );
};
