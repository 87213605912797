import { createContext, ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ISurveyAnswerItem } from "../libs/interfaces/services";
import surveysService from "../services/surveys";

interface ISurveyQuizProvider {
  children: ReactNode;
}

interface ITopicsID {
  idTopic: number;
}

interface ISurveyQuizContext {
  showSurveyQuiz: boolean;
  setShowSurveyQuiz: React.Dispatch<React.SetStateAction<boolean>>;
  surveyQuizIndex: number;
  setSurveyQuizIndex: React.Dispatch<React.SetStateAction<number>>;
  numTotalQuestions: number;
  setNumTotalQuestions: React.Dispatch<React.SetStateAction<number>>;
  answerList: ISurveyAnswerItem[];
  setAnswerList: React.Dispatch<React.SetStateAction<ISurveyAnswerItem[]>>;
  numberTopics: ITopicsID[];
  setNumberTopics: React.Dispatch<React.SetStateAction<ITopicsID[]>>;
  handleAttAnswerList: (
    surveyID?: string,
    hashID?: string,
    topicID?: string
  ) => Promise<void>;
  handleGetAnsweredList: (
    surveyID?: string,
    hashID?: string
  ) => Promise<ISurveyAnswerItem[]>;
}

export const SurveyQuizContext = createContext({} as ISurveyQuizContext);

export const SurveyQuizProvider = ({ children }: ISurveyQuizProvider) => {
  const [showSurveyQuiz, setShowSurveyQuiz] = useState(false);
  const [surveyQuizIndex, setSurveyQuizIndex] = useState(0);
  const [numTotalQuestions, setNumTotalQuestions] = useState<number>(0);
  const [answerList, setAnswerList] = useState<ISurveyAnswerItem[]>([]);
  const [numberTopics, setNumberTopics] = useState<ITopicsID[]>([]);

  const handleAttAnswerList = async (
    surveyID = "",
    hashID = "",
    topicID = ""
  ) => {
    const resp = await surveysService.listSurveyAnswer(surveyID, hashID);
    if (resp.object) {
      const filteredByTopicId = resp.object.filter(
        (answer) => answer.idTopic === Number(topicID)
      );
      setAnswerList(filteredByTopicId);
    }
  };

  const handleGetAnsweredList = async (surveyID = "", hashID = "") => {
    const resp = await surveysService.listSurveyAnswer(surveyID, hashID);
    // if (resp.object) {
    return resp.object;
    // } else {
    //   return [];
    // }
  };

  return (
    <SurveyQuizContext.Provider
      value={{
        showSurveyQuiz,
        setShowSurveyQuiz,
        surveyQuizIndex,
        setSurveyQuizIndex,
        numTotalQuestions,
        setNumTotalQuestions,
        answerList,
        setAnswerList,
        handleAttAnswerList,
        numberTopics,
        setNumberTopics,
        handleGetAnsweredList,
      }}
    >
      {children}
    </SurveyQuizContext.Provider>
  );
};
