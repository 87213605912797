import styles from './Footer.module.css'
import TextLogo from '../../assets/text-logo-black.png';

function Footer() {
	return (
		<footer className={styles.footer}>
			<img src={TextLogo} />
		</footer>
	);
}

export default Footer;
