import { FieldValues, UseFormRegister, UseFormWatch } from "react-hook-form";
import { ISurveyQuestionItemGroupsQuestionItem } from "../../libs/interfaces/services";
import { DefaultError } from "../DefaultError";
import { TextAreaDefault } from "../TextAreaDefault";
import styles from "./TextItem.module.css";
import { AIAssistantButton } from "../ChatWidget";
import { QuestionType } from "../../models/QuestionTypeEnum";
import useAssistantStore, { AssistantStore } from "../../store/assistantStore";

interface ITextProps {
  register: UseFormRegister<FieldValues>;
  id: string | number;
  errors?: any;
  text: string;
  watch: UseFormWatch<FieldValues>;
  isAssistantActive: boolean;
}

export const TextItem = ({ register, id, errors, text, watch, isAssistantActive }: ITextProps) => {
  const content = watch(`${id}`);

  const setInput = useAssistantStore((state: AssistantStore) => state.setInput);
  if (isAssistantActive) {
    setInput({
      type: QuestionType.Text,
      data: content,
      value: content
    });
  }

  return (
    <div className={styles.container}>
      <div
        dangerouslySetInnerHTML={{
          __html: text ? text : "",
        }}
      />
      <div>
        <div className={'buttonContainer'} style={{ marginBottom: "-10px" }}>

          <AIAssistantButton
            subQuestion={{ id: id as number, text: text }}
          />
        </div>
      </div>
      <TextAreaDefault subQuestion={{ id: id as number, text: text }} showAIAssistantButton={true} register={register(`${id}`)} errors={!!errors} />
      {errors && <DefaultError />}
    </div>
  );
};
