/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./Surveys.module.css";
import { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import DarkLogo from "../../assets/dark-logo.png";
import surveysService from "../../services/surveys";
import { ISurveysResponse, ISurveyItem } from "../../libs/interfaces/services";
import { useParams } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { useContextSurveyQuiz } from "../../hooks/useSurveyQuiz";
import ProgressBar from "../../components/ProgressBar";
import { orderByList } from "../../helpers/orderByList";
import GifLoading from "../../assets/gif_loading_orbs.gif";
import { BsQuestionOctagonFill } from "react-icons/bs";

interface IHelpText {
  description: string | undefined;
}
interface ILoading {
  isLoading: boolean;
  id: string | number;
}

interface ITopicArr {
  idTopic: string | number;
  count: number;
}

function InformationBox({ surveys }: any) {
  return (
    <div className={styles.informationBox}>
      {surveys?.surveyList ? (
        <div style={{ maxWidth: "1140px" }}>
          <h1>{surveys?.title}</h1>
          <h2>{surveys?.subtitle}</h2>
          <h3>{surveys?.description}</h3>
        </div>
      ) : (
        <div style={{ maxWidth: "1140px" }}>
          <h1>{surveys?.finalMessage?.title}</h1>
          <h2>{surveys?.finalMessage?.subtitle}</h2>
          <h3>{surveys?.finalMessage?.description}</h3>
        </div>
      )}
    </div>
  );
}

function SurveysContainer({ surveys }: any) {
  const { hashID } = useParams();
  const { setNumberTopics, handleGetAnsweredList } = useContextSurveyQuiz();
  const [loading, setLoading] = useState<ILoading>({
    isLoading: false,
    id: 0,
  });

  const surveyListOrganized = surveys?.object?.surveyList?.sort(orderByList);

  const orderByIdTopic = (arr: ITopicArr[]) => {
    const orderedArr = arr.sort((a, b) => {
      if (a.idTopic < b.idTopic) {
        return -1;
      }
      if (a.idTopic > b.idTopic) {
        return 1;
      }
      return 0;
    });

    return orderedArr;
  };

  const handleNavigateSurvey = async (idSurvey: string | number) => {
    setLoading({
      isLoading: true,
      id: idSurvey,
    });

    const listSurv = await surveysService.listSurveyQuestions(
      String(idSurvey),
      hashID ? hashID : ""
    );

    const object = listSurv.object.topics.sort((a, b) => {
      if (a.order > b.order) {
        return 1;
      }
      if (a.order < b.order) {
        return -1;
      }
      return 0;
    });

    const listAswered = await handleGetAnsweredList(String(idSurvey), hashID);
    const topics = object?.map((topic) => ({ idTopic: Number(topic.idTopic) }));

    const listAnsweredByTopic = topics.map(({ idTopic }) => {
      const count = listAswered.filter((answer) => answer.idTopic === idTopic);
      return { idTopic, count: count.length };
    });

    const numberQuestionsByTopic = object.map(({ questions, idTopic }) => {
      const numbQuest = questions[0].groupsQuestionItens.length;
      return { idTopic, count: numbQuest === 1 ? questions.length : numbQuest };
    });

    const allAnswered =
      JSON.stringify(orderByIdTopic(listAnsweredByTopic)) ===
      JSON.stringify(orderByIdTopic(numberQuestionsByTopic));

    setNumberTopics(topics);

    for (const quest of numberQuestionsByTopic) {
      const answereds = listAnsweredByTopic.find(
        ({ idTopic }) => Number(quest?.idTopic) === Number(idTopic)
      );

      if (!!answereds && quest.count > answereds.count) {
        window.location.href = `/${hashID}/survey-topic/${idSurvey}/${quest.idTopic}`;
        break;
      }
      if (!!answereds && quest.count < answereds.count) {
        window.location.href = `/${hashID}/survey-topic/${idSurvey}/${quest.idTopic}`;
        break;
      }
      if (allAnswered) {
        window.location.href = `/${hashID}/survey-topic/${idSurvey}/${quest.idTopic}`;
        break;
      }
    }

    // for (const obj of object) {
    //   const idTopic = obj?.idTopic;
    //   const answered = listAswered?.filter(
    //     (answer: any) => answer?.idTopic === idTopic
    //   ).length;

    //   if (obj?.questions?.length > answered) {
    //     window.location.href = `/${hashID}/survey-topic/${idSurvey}/${idTopic}`;
    //     break;
    //   } else {
    //     window.location.href = `/${hashID}/survey-topic/${idSurvey}/${idTopic}`;
    //     break;
    //   }
    // }

    setLoading({
      isLoading: false,
      id: idSurvey,
    });
  };

  return (
    <section className={styles.surveysContainer}>
      {!!surveyListOrganized &&
        surveyListOrganized?.map((survey: ISurveyItem) => {
          return (
            <button
              disabled={loading.isLoading}
              key={survey.idSurvey}
              onClick={() => handleNavigateSurvey(survey.idSurvey)}
              className={styles.surveyBox}
              style={loading.isLoading ? { cursor: "default" } : {}}
            >
              {loading.isLoading && loading.id === survey.idSurvey ? (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <img src={GifLoading} width={80} style={{ margin: 0 }} />
                </div>
              ) : (
                <>
                  <div className={styles.surveyBoxStatus}>
                    <div style={{ background: survey.surveyStatus.color }} />
                    <p>{survey.surveyStatus.description}</p>
                  </div>

                  <h3>{survey.title}</h3>
                  <h4>{survey.subtitle}</h4>

                  <div className={styles.surveyBoxProgress}>
                    <ProgressBar
                      description="Progresso"
                      type="dark"
                      percentage={survey?.percentAnswered}
                    />
                  </div>
                </>
              )}
            </button>
          );
        })}
    </section>
  );
}

function HelpText({ description }: IHelpText) {
  return (
    <div className={styles.helpText}>
      <h4>{description}</h4>
    </div>
  );
}

function Surveys() {
  const [loading, setLoading] = useState(true);
  const [surveys, setSurveys] = useState<ISurveysResponse>();

  const { hashID } = useParams();

  useEffect(() => {
    async function loadSurveys() {
      const response = await surveysService.listSurveys(hashID ? hashID : "");
      setSurveys(response);
      setLoading(false);
    }

    loadSurveys();
  }, []);

  return (
    <>
      <section className={styles.surveys}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <img src={DarkLogo} alt="dark logo" />
            <InformationBox surveys={surveys?.object} />
            <SurveysContainer surveys={surveys} />
            <HelpText description={surveys?.object?.descriptionFooter} />
          </>
        )}
      </section>
    </>
  );
}

export default Surveys;
