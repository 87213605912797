import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useLayoutEffect, useState } from "react";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./SurveyQuestions.module.css";
import Footer from "../../components/Footer";

import {
  ISurveyAnswerItem,
  ISurveyQuestion,
  ISurveyQuestionItemGroupsQuestion,
} from "../../libs/interfaces/services";
import { Header } from "../../components/Header";
import { useContextSurveyQuiz } from "../../hooks/useSurveyQuiz";
import { TextItem } from "../../components/TextItem";
import Button from "../../components/Button";
import { DateItem } from "../../components/DateItem";
import { CheckboxItem } from "../../components/CheckboxItem";
import { RadioItem } from "../../components/RadioItem";
import { IntItem } from "../../components/IntItem";
import surveysService from "../../services/surveys";
import { orderByItems } from "../../helpers/orderByList";
import useAssistantStore, { AssistantStore } from "../../store/assistantStore";
import { QuestionType } from "../../models/QuestionTypeEnum";

interface ISurveyQuestions {
  surveyTopic: ISurveyQuestion;
  indexQuiz: number;
}

interface IAskBox {
  title: string;
  subtitle: string;
  text: string;
  indexQuiz: number;
}

export function SurveyQuestions({ surveyTopic, indexQuiz }: ISurveyQuestions) {
  const [loading, setLoading] = useState(false);
  const {
    setShowSurveyQuiz,
    surveyQuizIndex,
    setSurveyQuizIndex,
    answerList,
    handleAttAnswerList,
    numberTopics,
    numTotalQuestions,
  } = useContextSurveyQuiz();

  const params = useParams();
  const navigate = useNavigate();
  const { surveyID, surveyTopicID, hashID } = params;

  const setHashId = useAssistantStore((state: AssistantStore) => state.setHashId);

  useEffect(() => {
    setHashId(hashID!);
  }, [hashID]);

  const [groupQuestionsType, setGroupQuestionsType] = useState(
    surveyTopic.questions[indexQuiz].groupsQuestionItens.map(
      (group) => group.questionType
    )
  );

  useEffect(() => {
    const groupsQuestions = surveyTopic.questions[
      indexQuiz
    ].groupsQuestionItens.map((group) => group.questionType);

    setGroupQuestionsType(groupsQuestions);
  }, [surveyQuizIndex]);

  const handleGoBack = () => {
    deactivateAssistant();
    if (surveyQuizIndex === 0) {
      setShowSurveyQuiz(false);
    } else {
      setSurveyQuizIndex((old) => old - 1);
    }
  };

  const createValidateSchema = () => {
    if (!!surveyTopic?.questions[indexQuiz]?.groupsQuestionItens?.length) {
      let schema = {};
      const schemaArr = surveyTopic.questions[
        indexQuiz
      ].groupsQuestionItens.map((item) => {
        switch (item.questionType) {
          case QuestionType.Text: {
            if (item.required) {
              return {
                [item.idSubQuestion]: yup.string().required(),
              };
            } else {
              return {
                [item.idSubQuestion]: yup.string(),
              };
            }
          }

          case QuestionType.Int: {
            if (item.required) {
              return {
                [item.idSubQuestion]: yup
                  .number()
                  .integer()
                  .positive()
                  .required(),
              };
            } else {
              return {
                [item.idSubQuestion]: yup.number().integer().positive(),
              };
            }
          }

          case QuestionType.Date: {
            if (item.required) {
              return {
                [item.idSubQuestion]: yup.date().required(),
              };
            } else {
              return {
                [item.idSubQuestion]: yup.date(),
              };
            }
          }

          case QuestionType.MultiSelect: {
            if (item.required) {
              return {
                [item.idSubQuestion]: yup
                  .array().of(yup.mixed())
                  .test((value) => !!value && value.some(Boolean))
                  .required(),
              };
            } else {
              return {
                [item.idSubQuestion]: yup.array().of(yup.mixed()),
              };
            }
          }

          case QuestionType.Radio:
          case QuestionType.Scale: {
            if (item.required) {
              return {
                [item.idSubQuestion]: yup.number().required(),
              };
            } else {
              return {
                [item.idSubQuestion]: yup.number(),
              };
            }
          }

          default:
            return {};
        }
      });

      schemaArr.forEach((schemaItem) => {
        schema = { ...schema, ...schemaItem };
      });

      return schema;
    }

    return {};
  };

  const createDefaultValues = () => {
    if (
      !!answerList.length &&
      surveyTopic?.questions[indexQuiz]?.groupsQuestionItens?.length
    ) {
      let defaultValues = {};

      const defaultValuesArr = answerList.map((answer) => {
        const type = surveyTopic?.questions[
          indexQuiz
        ]?.groupsQuestionItens.find(
          (question) =>
            question.idSubQuestion === answer.idSubQuestion
        )?.questionType;

        switch (type) {
          case QuestionType.Text: {
            return { [answer.idSubQuestion]: answer.answerString };
          }

          case QuestionType.Int: {
            return { [answer.idSubQuestion]: answer.answerInt };
          }

          case QuestionType.Date: {
            if (answer.answerDate) {
              return {
                [answer.idSubQuestion]: new Date(answer.answerDate)
                  .toISOString()
                  .split("T")[0],
              };
            }
            return {};
          }

          case QuestionType.MultiSelect: {
            return {
              [answer.idSubQuestion]: JSON.parse(answer.answerArray),
            };
          }

          case "Radio":
          case "Scale": {
            return { [answer.idSubQuestion]: answer.answerInt };
          }
        }

        return null;
      });

      defaultValuesArr
        .filter((item) => item)
        .forEach((defaultValueItem) => {
          defaultValues = { ...defaultValues, ...defaultValueItem };
        });

      return defaultValues;
    }

    return {} as any;
  };

  const schema = yup.object(createValidateSchema()).required();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: createDefaultValues(),
  });

  useLayoutEffect(() => {
    const defaultValues = createDefaultValues();
    for (const key in defaultValues) {
      setValue(`${key}`, defaultValues[key]);
    }
  }, [surveyQuizIndex]);

  const errorUnType: any = errors;

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    let responseArr = [];

    for (const key in data) {
      const typeQuest = surveyTopic.questions[
        indexQuiz
      ].groupsQuestionItens.find(
        (quest) => quest.idSubQuestion === Number(key)
      )?.questionType;


      const idAnswer = answerList.find(
        (answer) => answer.idSubQuestion === Number(key)
      )?.idAnswer;

      switch (typeQuest) {
        case QuestionType.Text: {
          const response: ISurveyAnswerItem = {
            idAnswer: idAnswer ? idAnswer : 0,
            idQuestion: Number(
              surveyTopic.questions[indexQuiz].groupsQuestionItens[0].idQuestion
            ),
            idTopic: Number(surveyTopicID),
            idSurvey: Number(surveyID),
            answerInt: 0,
            answerArray: "",
            answerString: data[key],
            idSubQuestion: Number(key),
          };
          responseArr.push(response);
          break;
        }

        case QuestionType.Int: {
          const response: ISurveyAnswerItem = {
            idAnswer: idAnswer ? idAnswer : 0,
            idQuestion: Number(
              surveyTopic.questions[indexQuiz].groupsQuestionItens[0].idQuestion
            ),
            idTopic: Number(surveyTopicID),
            idSurvey: Number(surveyID),
            answerInt: Number(data[key]),
            answerArray: "",
            answerString: null,
            idSubQuestion: Number(key),
          };
          responseArr.push(response);
          break;
        }

        case QuestionType.Date: {
          const response: ISurveyAnswerItem = {
            idAnswer: idAnswer ? idAnswer : 0,
            idQuestion: Number(
              surveyTopic.questions[indexQuiz].groupsQuestionItens[0].idQuestion
            ),
            idTopic: Number(surveyTopicID),
            idSurvey: Number(surveyID),
            answerInt: 0,
            answerArray: "",
            answerString: null,
            idSubQuestion: Number(key),
            answerDate: String(new Date(data[key]).toISOString()),
          };
          responseArr.push(response);
          break;
        }

        case QuestionType.Scale:
        case QuestionType.Radio: {
          const response: ISurveyAnswerItem = {
            idAnswer: idAnswer ? idAnswer : 0,
            idQuestion: Number(
              surveyTopic.questions[indexQuiz].groupsQuestionItens[0].idQuestion
            ),
            idTopic: Number(surveyTopicID),
            idSurvey: Number(surveyID),
            answerInt: Number(data[key]),
            answerArray: "",
            answerString: null,
            idSubQuestion: Number(key),
          };
          responseArr.push(response);
          break;
        }

        case QuestionType.MultiSelect: {
          const filteredArr = data[key]
            ?.filter((item: any) => item !== "false")
            .map((item: any) => Number(item));

          const response: ISurveyAnswerItem = {
            idAnswer: idAnswer ? idAnswer : 0,
            idQuestion: Number(
              surveyTopic.questions[indexQuiz].groupsQuestionItens[0].idQuestion
            ),
            idTopic: Number(surveyTopicID),
            idSurvey: Number(surveyID),
            answerInt: 0,
            answerArray: JSON.stringify(filteredArr),
            answerString: null,
            idSubQuestion: Number(key),
          };
          responseArr.push(response);
          break;
        }

        default:
          continue;
      }
    }

    const response = await surveysService.SurveyAnswerList(
      responseArr,
      hashID ? hashID : ""
    );

    if (response.status === 200) {
      toast.success("Resposta salva com sucesso!");
      reset();
      nextScreen();
    } else {
      toast.error("Não foi possivel enviar sua resposta.");
    }

    setLoading(false);
  });

  const deactivateAssistant = useAssistantStore((state: AssistantStore) => state.deactivateAssistant);

  const handleBack = () => {
    deactivateAssistant();
    if (surveyQuizIndex === 0) {
      setShowSurveyQuiz(false);
    } else {
      setSurveyQuizIndex((old) => old - 1);
    }
  };

  const nextScreen = async () => {
    await handleAttAnswerList(surveyID, hashID, surveyTopicID);

    const currentIndex = numberTopics
      .map((idTopic) => idTopic?.idTopic)
      .indexOf(surveyTopicID ? Number(surveyTopicID) : 1);

    if (numTotalQuestions === surveyQuizIndex + 1) {
      if (numberTopics.length > 1 && currentIndex < numberTopics.length - 1) {
        window.location.href = `/${hashID}/survey-topic/${surveyID}/${numberTopics[currentIndex + 1]?.idTopic
          }`;
      } else {
        navigate(`/${hashID}/survey-finish/${surveyID}`);
      }
    } else {
      setSurveyQuizIndex((old) => old + 1);
    }
  };

  const getQuestionType = (surveyItem: ISurveyQuestionItemGroupsQuestion, isAssistantActive: boolean) => {
    switch (surveyItem.questionType) {
      case QuestionType.Text:
        return (
          <TextItem
            key={surveyItem.order}
            register={register}
            watch={watch}
            id={surveyItem.idSubQuestion}
            errors={errorUnType[surveyItem.idSubQuestion]}
            text={surveyItem.text}
            isAssistantActive={isAssistantActive}
          />
        );

      case QuestionType.Int:
        return (
          <IntItem
            key={surveyItem.order}
            register={register}
            watch={watch}
            options={surveyItem.questionItens}
            id={surveyItem.idSubQuestion}
            errors={errorUnType[surveyItem.idSubQuestion]}
            text={surveyItem.text}
            isAssistantActive={isAssistantActive}
          />
        );

      case QuestionType.Date:
        return (
          <DateItem
            key={surveyItem.order}
            register={register}
            watch={watch}
            id={surveyItem.idSubQuestion}
            errors={errorUnType[surveyItem.idSubQuestion]}
            text={surveyItem.text}
            isAssistantActive={isAssistantActive}
          />
        );

      case QuestionType.MultiSelect:
        return (
          <CheckboxItem
            key={surveyItem.order}
            text={surveyItem.text}
            register={register}
            id={surveyItem.idSubQuestion}
            options={surveyItem.questionItens}
            errors={errorUnType[surveyItem.idSubQuestion]}
            watch={watch}
            isAssistantActive={isAssistantActive}
          />
        );

      case QuestionType.Radio:
      case QuestionType.Scale:
        return (
          <RadioItem
            key={surveyItem.order}
            text={surveyItem.text}
            register={register}
            id={surveyItem.idSubQuestion}
            options={surveyItem.questionItens}
            errors={errorUnType[surveyItem.idSubQuestion]}
            watch={watch}
            isAssistantActive={isAssistantActive}
          />
        );

      default:
        return 0;
    }
  };

  const isAssistantActive = useAssistantStore((state: AssistantStore) => state.isActive);
  const { idSubQuestion } = useAssistantStore((state: AssistantStore) => state.config);

  return (
    <>
      <section className={`${styles.container} ${isAssistantActive ? styles.containerWithAssistant : ""}`}>
        <Header handleNavigate={() => handleGoBack()} />
        <div className={styles.box}>
          <AskBox
            {...surveyTopic}
            text={surveyTopic.questions[indexQuiz].text}
            indexQuiz={indexQuiz}
          />
          <form
            style={{ maxWidth: "1140px", padding: "0px 20px" }}
            onSubmit={onSubmit}
          >
            {surveyTopic.questions[indexQuiz].groupsQuestionItens
              .sort(orderByItems)
              .map((surveyItem, index) => {
                return (
                  <div
                    className={`${isAssistantActive && idSubQuestion === surveyItem.idSubQuestion
                      ? styles.selectedQuestions
                      : styles.unselectedQuestions
                      } ${styles.subQuestionItem}`}
                  >
                    {getQuestionType(surveyItem, isAssistantActive && idSubQuestion === surveyItem.idSubQuestion)}
                  </div>
                );
              })}

            <div
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              <Button
                handleAction={() => handleBack()}
                text="Voltar"
                type="darkTransparent"
                width="170px"
                typeButton="button"
              />

              <Button
                disabled={false}
                text={"Enviar Resposta"}
                type="blueWhite"
                width="170px"
                loading={loading}
              />
            </div>
          </form>

          {/* {groupQuestionsType.includes("Radio") && (
            <RadioQuiz
              justify={groupQuestionsType.includes("Text")}
              indexQuiz={indexQuiz}
              questionItens={surveyTopic.questions[indexQuiz]}
            />
          )} */}
          {/* {groupQuestionsType.includes("MultiSelect") && (
            <CheckBoxQuiz
              justify={groupQuestionsType.includes("Text")}
              indexQuiz={indexQuiz}
              questionItens={surveyTopic.questions[indexQuiz]}
            />
          )} */}
          {/* 

          {groupQuestionsType.includes("Text") &&
            !groupQuestionsType.includes("MultiSelect") &&
            !groupQuestionsType.includes("Radio") && (
              <TextQuiz
                justify={groupQuestionsType.includes("Text")}
                questionItens={surveyTopic.questions[indexQuiz]}
              />
            )} */}
        </div>
      </section>
    </>
  );
}

function AskBox({ title, subtitle, text, indexQuiz }: IAskBox) {
  return (
    <div className={styles.askBox}>
      <h1>{title}</h1>
      {/* <h2>{subtitle}</h2> */}
      {/* <h3>{`${indexQuiz + 1}. ${text}`}</h3> */}
      <div
        dangerouslySetInnerHTML={{
          __html: text ? text : "",
        }}
      />
    </div>
  );
}
