import React, {
  ChangeEventHandler,
  RefObject,
  TextareaHTMLAttributes,
  useState,
} from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import styles from "./styles.module.css";
import { AIAssistantButton } from "../ChatWidget";
import { QuestionType } from "../../models/QuestionTypeEnum";

interface ITextAreaDefault {
  register?: UseFormRegisterReturn;
  errors?: boolean;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  showAIAssistantButton?: boolean;
  subQuestion?: { id: number, text: string };
}

export const TextAreaDefault = React.forwardRef(
  (props: ITextAreaDefault, ref) => {
    const [text, setText] = useState("");
    return (
      <div className={styles.container}>
        <textarea
          style={props.errors ? { borderColor: "red" } : {}}
          className={styles.inputText}
          onChange={(e) => {
            setText(e.target.value);
            props.onChange?.(e);
          }}
          maxLength={4096}
          {...props.register}
        />
      </div>

    );
  }
);
