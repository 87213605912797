import { create } from 'zustand';
import { AIAssistantInputGeneric } from '../components/ChatWidget/model';

interface AssistantState {
    hasAssistant: boolean;
    isActive: boolean;
    hashId: string | null;
    config: {
        idSubQuestion: number | null;
        subQuestionText: string | null;
    };
    input: AIAssistantInputGeneric | null;
    idChat: number | null;
    chatMessages: {
        role: "user" | "assistant";
        content: string;
    }[];
}

interface AssistantActions {
    activateAssistant: (idSubQuestion: number, subQuestionText: string) => void;
    setInput: (input: AIAssistantInputGeneric) => void;
    deactivateAssistant: () => void;
    setIdChat: (idChat: number) => void;
    addChatMessage: (message: { role: "user" | "assistant"; content: string }) => void;
    setHashId: (idRespondant: string) => void;
    setHasAssistant: (hasAssistant: boolean) => void;
    undoLastMessage: () => void;
}

type AssistantStore = AssistantState & AssistantActions;

const useAssistantStore = create<AssistantStore>((set) => ({
    hasAssistant: false,
    isActive: false,
    config: {
        idSubQuestion: null,
        idRespondant: null,
        subQuestionText: null,
    },
    input: null,
    hashId: null,
    idChat: null,
    chatMessages: [],
    addChatMessage: (message: { role: "user" | "assistant"; content: string }) => set((state) => ({ chatMessages: [...state.chatMessages, message] })),
    setHashId: (hashId: string) => set((state) => ({ ...state, hashId: hashId })),
    setIdChat: (idChat: number) => set((state) => ({ ...state, idChat: idChat })),
    setHasAssistant: (hasAssistant: boolean) => set((state) => ({ ...state, hasAssistant: hasAssistant })),
    undoLastMessage: () => set((state) => ({ chatMessages: state.chatMessages.slice(0, -1) })),
    activateAssistant: (idSubQuestion: number, subQuestionText: string) => {
        set((state) => (
            {
                idChat: null,
                hashId: state.hashId,
                isActive: true,
                config: {
                    idSubQuestion,
                    subQuestionText
                },
                chatMessages: []
            })
        );
    },
    setInput: (input: AIAssistantInputGeneric) => set((state) => ({ ...state, input: input })),
    deactivateAssistant: () => set({ isActive: false, config: { idSubQuestion: null, subQuestionText: null }, chatMessages: [], idChat: null }),
}));

export default useAssistantStore;
export type { AssistantStore };