/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./Survey.module.css";
import { useEffect, useState } from "react";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import surveysService from "../../services/surveys";
import { useParams } from "react-router-dom";
import { ISurveyQuestion } from "../../libs/interfaces/services";
import { SurveyQuestions } from "../SurveyQuestions";
import { Header } from "../../components/Header";
import { useContextSurveyQuiz } from "../../hooks/useSurveyQuiz";

import gifOpen from "../../assets/gif_open_2.gif";
import { Loading } from "../../components/Loading";
import useAssistantStore, { AssistantStore } from "../../store/assistantStore";

function SurveyTopicHome() {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [surveyTopic, setSurveyTopic] = useState<ISurveyQuestion>();

  const { hashID, surveyID, surveyTopicID } = params;

  const {
    showSurveyQuiz,
    setShowSurveyQuiz,
    surveyQuizIndex,
    setNumTotalQuestions,
    handleAttAnswerList,
    answerList,
    setNumberTopics,
    setSurveyQuizIndex,
  } = useContextSurveyQuiz();

  const setHasAssistant = useAssistantStore((state: AssistantStore) => state.setHasAssistant);

  useEffect(() => {
    async function loadSurveyQuestion() {
      if (params.surveyID) {
        const response = await surveysService.listSurveyQuestions(
          params.surveyID,
          params.hashID ? params.hashID : ""
        );

        setHasAssistant(response.object.hasAssistant);

        await handleAttAnswerList(
          params.surveyID,
          params.hashID,
          surveyTopicID
        );

        setSurveyTopic(
          response.object.topics.find(
            (topic) => topic.idTopic === Number(surveyTopicID)
          )
        );
        setLoading(false);
      }
    }
    loadSurveyQuestion();
  }, []);

  useEffect(() => {
    async function loadSurveyTopics() {
      const response = await surveysService.listSurveyQuestions(
        String(surveyID),
        hashID ? hashID : ""
      );

      setHasAssistant(response.object.hasAssistant);

      setNumberTopics(
        response.object.topics.map((topic) => ({ idTopic: Number(topic.idTopic) }))
      );
    }
    loadSurveyTopics();
  }, []);

  useEffect(() => {
    if (surveyTopic && answerList && answerList?.length > 0) {
      const quest = surveyTopic?.questions.map(
        (question) => question?.idQuestion
      );

      const answers = answerList?.map((answer) => answer.idQuestion);

      const notAnswer = quest?.filter(
        (question) => !answers.includes(question)
      );

      const firstNotAnswer = quest?.indexOf(notAnswer[0]);

      if (answers.length >= quest.length) {
        setSurveyQuizIndex(0);
      }

      if (notAnswer.length > 0) {
        setSurveyQuizIndex(firstNotAnswer);
      }
    }
  }, [surveyTopic]);

  function handleChangeStartQuiz() {
    setShowSurveyQuiz(true);
    setNumTotalQuestions(
      surveyTopic?.questions ? surveyTopic?.questions.length : 0
    );
  }

  function handleBack() {
    window.location.href = `/${params.hashID}`;
  }

  const isAssistantActive = useAssistantStore((state: AssistantStore) => state.isActive);

  return (
    <>
      <div className={`${styles.survey} ${isAssistantActive ? styles.surveyWithAssistant : ""}`}>
        {loading ? (
          <Loading />
        ) : !showSurveyQuiz ? (
          <>
            <div className={styles.box}>
              <Header handleNavigate={() => handleBack()} />
              <div className={styles.surveyIconContainer}>
                <img src={gifOpen} alt="gif open" className={styles.gifOpen} />
              </div>
            </div>

            <div className={styles.surveyContent}>
              <div className={styles.surveyContentText}>
                <h1>{surveyTopic?.title}</h1>
                <h2 style={{ textAlign: "left" }}>{surveyTopic?.subtitle}</h2>
                <div
                  style={{ textAlign: "left" }}
                  dangerouslySetInnerHTML={{
                    __html: surveyTopic?.description
                      ? surveyTopic?.description
                      : "",
                  }}
                />
              </div>

              <div className={styles.surveyContentFooter}>
                <Button
                  handleAction={() => handleChangeStartQuiz()}
                  text="Iniciar"
                  type="lightBlue"
                  width="100%"
                />
              </div>
            </div>
          </>
        ) : (
          <SurveyQuestions
            surveyTopic={surveyTopic ? surveyTopic : ({} as ISurveyQuestion)}
            indexQuiz={surveyQuizIndex}
          />
        )}
      </div>
    </>
  );
}

export default SurveyTopicHome;
