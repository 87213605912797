import api from "./api";
import URLS from "../constants/urls";
import { ApiResponse } from "../libs/interfaces/services";
import { AIAssistantInputGeneric } from "../components/ChatWidget/model";

interface AskAssistantParams {
  idChat?: number;
  idSubQuestion: number;
  hashId: string;
  input: AIAssistantInputGeneric;
  question: string;
}

interface AssistantResponse {
  idChat: number;
  text: string;
}

async function askAssistant(params: AskAssistantParams): Promise<AssistantResponse> {
  const endpoint = URLS.ASSISTANT_ADD_MESSAGE();
  let retries = 0;
  const maxRetries = 3;
  let data;

  while (retries < maxRetries) {
    try {
      const response = await api.post<ApiResponse<AssistantResponse>>(endpoint, params);
      data = response.data;
      break;
    } catch (error) {
      retries++;
      if (retries === maxRetries) {
        throw error;
      }
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }

  if (!data) {
    throw new Error("Não foi possível obter resposta do assistente");
  }

  return data.object;
}

async function getAssistantChat(idSubQuestion: number, hashId: string): Promise<ApiResponse<AssistantResponse>> {
  const endpoint = URLS.ASSISTANT_GET_CHAT(idSubQuestion, hashId);

  const { data } = await api.get<ApiResponse<AssistantResponse>>(endpoint);

  return data;
}

export {
  askAssistant,
  getAssistantChat,
};
