import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import InputMask from "react-input-mask";

import styles from "./styles.module.css";

interface IInputMasked {
  register: UseFormRegisterReturn;
  errors: boolean;
}

export const InputMasked = React.forwardRef(
  ({ register, errors }: IInputMasked) => {
    return (
      <div className={styles.containerInputMasked}>
        <input
          className={styles.inputDate}
          style={errors ? { borderColor: "red" } : {}}
          type="date"
          {...register}
        />
      </div>
    );
  }
);
