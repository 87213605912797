import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Survey from "./pages/SurveyTopics";
import Surveys from "./pages/Surveys";
import SurveyCompleted from "./pages/SurveyCompleted";
import { SurveyQuestions } from "./pages/SurveyQuestions";
import SurveyTopicHome from "./pages/SurveyTopicHome";
import { SurveyQuizProvider } from "./contexts/surveyQuizContext";
import { Toaster } from "react-hot-toast";
import SurveyTopicFinish from "./pages/SurveyTopicFinish";
import { SurveyDefault } from "./pages/SurveyDefault";
import SurveyTopicContinue from "./pages/SurveyTopicContinue";
import { ThemeProvider } from "@material-ui/core";
import { theme } from "./theme";
import ChatWidget from "./components/ChatWidget";
import Footer from "./components/Footer";
import useAssistantStore, { AssistantStore } from "./store/assistantStore";

const RouteChangeLogger = () => {
  const location = useLocation();
  const deactivateAssistant = useAssistantStore((state: AssistantStore) => state.deactivateAssistant);

  useEffect(() => {
    deactivateAssistant();
  }, [location]);

  return null;
};

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <SurveyQuizProvider>
        <BrowserRouter>
          <RouteChangeLogger />
          <Routes>
            <Route path="/" element={<SurveyDefault />} />
            <Route path="/:hashID" element={<Surveys />} />
            <Route path="/:hashID/survey/:surveyID" element={<Survey />} />
            <Route
              path="/:hashID/survey-completed/:surveyID"
              element={<SurveyCompleted />}
            />
            <Route
              path="/:hashID/survey-questions/:surveyID"
              element={SurveyQuestions}
            />
            <Route
              path="/:hashID/survey-topic/:surveyID/:surveyTopicID"
              element={<SurveyTopicHome />}
            />
            <Route
              path="/:hashID/survey-finish/:surveyID"
              element={<SurveyTopicFinish />}
            />
            <Route
              path="/:hashID/survey-continue/"
              element={<SurveyTopicContinue />}
            />
          </Routes>
        </BrowserRouter>
        <Toaster />
        <ChatWidget />
      </SurveyQuizProvider>
      <Footer />
    </ThemeProvider>
  );
};