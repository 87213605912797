import styles from "./ProgressBar.module.css"

interface IProgressBarProps {
  description: string;
  percentage: number;
  type: "dark" | "light";
}

const ProgressBar: React.FC<IProgressBarProps> = ({
  description,
  percentage,
  type,
}) => {
  return (
    <div className={styles.progressBarContainer}>
      <div className={styles.bar}>
        <div className={styles.progress} style={{ width: percentage + "%" }} />
      </div>
      <div className={styles.informations}>
        <p className={`${type}`}>{description}</p>
        <p className={`${type}`}>{percentage}%</p>
      </div>
    </div>
  );
};

export default ProgressBar;
